import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import Modal from '../components/Modal';

const ModalTesting = (props) => {
  if (!props) return null;

  const footerContent = props.data.allContentfulFooter.edges[0].node;
  const headerContent = props.data.allContentfulHeader.edges[0].node;
  const path = '/modal-testing';
  const node_locale = 'en';
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const isBrowser = typeof window !== "undefined";
  if (isBrowser && window.location.href.indexOf('/modal-testing') >= 0) {
    var m = document.createElement('meta');
    m.name = 'robots';
    m.content = 'noindex';
    document.head.appendChild(m);
  }
  return (
    <Layout
      themeColor="mode--light"
      currentLocation={path}
      currentNodeLocale={node_locale}
      footerContent={footerContent}
      headerContent={headerContent}
    >
      <section className="section">
        <div className="container">
          <div>
            <div onClick={() => setShowModal1(!showModal1)} className="pricing__action">
              <button class="button button--primary inline-block">Fullwidth modal</button>
            </div>
            <Modal onClose={() => setShowModal1(!showModal1)} show={showModal1} purchaseModal={true}>
              <iframe
                src="https://ext.gaius.services/uca-gs/home?p0=d&p1=3776_3683&p2=7281_2203&p3=2034_5831&pEGaiusId=123456&pEFirstName=Roger&pELastName=Wieder&pECellphoneNumber=0174%209793717&pEEmail=roger@besecured.de&pPLocale=de&pPCase=nS&pPTid=2021_0301&pPPm=1&pPS1=140_485&pPS2=0&pPS3=0&pX=_ext_7191_9958"
                width="100%"
                height="100%"
              ></iframe>
            </Modal>
            <div onClick={() => setShowModal2(!showModal2)} className="pricing__action">
              <button class="button button--primary inline-block">No fullwidth modal</button>
            </div>
            <Modal onClose={() => setShowModal2(!showModal2)} show={showModal2} purchaseModal={false}>
              <iframe
                src="https://ext.gaius.services/uca-gs/home?p0=d&p1=3776_3683&p2=7281_2203&p3=2034_5831&pEGaiusId=123456&pEFirstName=Roger&pELastName=Wieder&pECellphoneNumber=0174%209793717&pEEmail=roger@besecured.de&pPLocale=de&pPCase=nS&pPTid=2021_0301&pPPm=1&pPS1=140_485&pPS2=0&pPS3=0&pX=_ext_7191_9958"
                width="100%"
                height="100%"
              ></iframe>
            </Modal>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const page404Query = graphql`
  query {
    allContentfulHeader {
      edges {
        node {
          node_locale
          enableNotificationBar
          notificationBar {
            json
          }
          logo {
            file {
              url
            }
          }
          navigation {
            linkName
            link
            enableLink
            externalLink
            extraClasses
            badge
          }
          menuPlans {
            linkName
            link
            enableLink
            externalLink
            extraClasses
            badge
          }
          helpline {
            linkName
            link
            enableLink
            externalLink
            extraClasses
            badge
          }
          cookies {
            content {
              json
            }
            essentialSubCategories {
              cookieSubCategory
              cookieValue
              subCategoryDescription {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
            optionalSubCategories {
              cookieSubCategory
              cookieValue
              subCategoryDescription {
                childMarkdownRemark {
                  rawMarkdownBody
                }
              }
            }
            buttons {
              linkName
              link
              enableLink
              externalLink
              extraClasses
              badge
            }
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          mobileFloatingMenu {
            linkName
            link
            enableLink
            externalLink
            extraClasses
          }
          menuPlans {
            linkName
            link
            enableLink
            externalLink
            extraClasses
            badge
          }
          accordion {
            title
            columnPlacement
            accordionEntries {
              linkName
              link
              enableLink
              externalLink
              extraClasses
            }
          }
          footerGallery {
            json
          }
          disclaimer {
            json
          }
          copyright
          social {
            file {
              url
            }
            description
          }
          socialLinks
          madeWithLove
          node_locale
        }
      }
    }
  }
`;

export default ModalTesting;
