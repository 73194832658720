import React, { useState, useEffect, useRef } from 'react';

const Modal = (props) => {
  let modalRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', (event) => {
      if (modalRef.current) {
        if (modalRef.current === event.target) {
          props.onClose();
        }
      }
    });

    if (props.show) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflow = null;
      document.body.style.height = null;
    }
  }, [props.show]);

  if (!props.show) {
    return null;
  }

  return (
    <div
      ref={modalRef}
      className={
        props.transparent
          ? 'modal modal--transparent'
          : props.purchaseModal
          ? 'modal modal--purchase modal--full'
          : 'modal'
      }
    >
      <div className={props.purchaseModal ? 'modal__content modal__content--full' : 'modal__content'}>
        <a className="modal__close" onClick={() => props.onClose()}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
        <div className={'modal__context'}>{props.children && props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
